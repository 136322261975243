import React from 'react';
import Btnwapp from './BtnWapp';

function Home() {
  const imageUrls = 'https://cdn.chpriv.com/images/icono.png';
//Unlocking Potential, Together

  return (
    <>
    <div className="App">
      <header className="App-header">
      <img className="iMplrs-zoomIn" src={imageUrls} alt="Comunidad" />
      <p className="text-min">CHPRIV</p>
      </header>
    </div>
    </>
  );
}

export default Home;
